<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="p-1"
    >
      <b-navbar-brand
        class="navbar-brand p-0"
        :to="{ name: logoRoute }"
      >
        <img
          src="../../assets/img/paper-plane.png"
          alt="IFE Logo"
          class="brand p-1"
        >
        IFE Data
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav v-if="hasPermission('ROLE_NAVIGATION_USER')">
          <b-nav-item-dropdown
            v-for="item in navigationItems"
            :key="item.label"
            :text="item.label"
          >
            <b-dropdown-group
              v-for="(subItem, key) in item.items"
              :key="`${item.label}-${subItem.group}`"
              :header-tag="subItem.hasOwnProperty('label') ? subItem.label : null"
            >
              <b-dropdown-item
                v-for="link in subItem.options"
                :key="`${subItem.group}-${link.label}`"
                @click.exact="navigateTo(link)"
                @click.ctrl="navigateTo(link, true)"
                @click.meta="navigateTo(link, true)"
                @click.ctrl.shift="navigateTo(link, true, true)"
                @click.meta.shift="navigateTo(link, true, true)"
                @click.alt.shift="navigateTo(link, false, false, true)"
              >
                {{ link.label }}
              </b-dropdown-item>
              <b-dropdown-divider v-if="key !== item.items.length - 1" />
            </b-dropdown-group>
          </b-nav-item-dropdown>

          <b-nav-form
            autocomplete="off"
            class="pl-3 pr-3"
          >
            <global-search />
          </b-nav-form>
        </b-navbar-nav>

        <b-navbar-nav class="m-auto" />

        <b-navbar-nav class="ml-auto pr-2">
          <b-nav-item @click="$bvModal.show('user')">
            <span
              v-if="user && user.name"
              style="margin-right: 5px;"
            >
              {{ user.name.split(' ')[0] }}
            </span>
            <icon-user />
          </b-nav-item>
          <b-nav-item @click="logout">
            <icon-logout />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal
      id="user"
      size="lg"
      header-text-variant="white"
      header-bg-variant="dark"
      title="User Information"
      ok-title="Edit Profile"
      @ok="$router.push({ name: 'profile' })"
    >
      <user :user="user" />
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import GlobalSearch from '@/Client/Component/GlobalSearch';
import Navigation from '@/Client/Class/System/Navigation';
import User from '@/Client/Component/Card/User';
import UserPreferences from '@/Client/Model/UserPreferences';

export default {
  name: 'Navigation',
  components: {
    'global-search': GlobalSearch,
    'user': User,
  },
  data() {
    return {
      navigationItems: [],
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    logoRoute() {
      if (!this.hasPermission('ROLE_NAVIGATION_USER') &&
          this.hasPermission('ROLE_AIRLINE_CLIENT_PORTAL_USER')) {
        return 'airlineClientPortal';
      }

      return 'home';
    },
  },
  mounted() {
    const nav = new Navigation();
    this.navigationItems = nav.getUserNavOptions(this.user, this.$store.getters);
  },
  methods: {
    /**
     * Logs the user out
     */
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$store.commit('resetState');
        this.$store.dispatch('logout');
        if (UserPreferences.getCompanyLogin()) {
          const companyLogin = UserPreferences.getCompanyLogin();
          this.$router.push({name: 'companyLogin', params: {company: companyLogin.name}});
        } else {
          this.$router.push({name: 'login'});
        }
      });
    },
  },
};
</script>

<style scoped>
    .navbar-brand {
        max-height: 38px;
    }

    .brand {
        max-height: inherit;
    }
</style>
