import defaultCurrency from '@/Client/Store/Collection/Currency';

export default {
  methods: {
    formatCurrencyValueForSubmission(currencyValueObject) {
      const obj = {...currencyValueObject};

      if (!obj.currency) {
        obj.currency = defaultCurrency;
      }

      delete obj.__typename;
      delete obj._id;

      obj.currency = obj.currency.id;
      return obj;
    },
    getCurrencyOutput(currencyValueObject, locale = null) {
      // If no locale is passed in, take the first from the browser
      if (null === locale) {
        const languages = navigator.languages;
        locale = languages[0];
      }

      if (undefined === currencyValueObject || null === currencyValueObject || '' === currencyValueObject) {
        return Number(0.00).toLocaleString(locale, {minimumFractionDigits: 2});
      }

      let out = '';
      if (null !== currencyValueObject.currency && undefined !== currencyValueObject.currency) {
        out += currencyValueObject.currency.code !== '' ?
          currencyValueObject.currency.code :
          currencyValueObject.currency.name + ' ';
      }

      if (null !== currencyValueObject.valueAsFloat) {
        out += Number(currencyValueObject.valueAsFloat).toLocaleString(locale, {minimumFractionDigits: 2});
        return out;
      }

      out += 0;
      return Number(out).toLocaleString(locale, {minimumFractionDigits: 2});
    },
  },
};
